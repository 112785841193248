import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { theme } from "styles/theme";
import { Person } from "@styled-icons/bootstrap/Person";

export const HeaderWrapper = styled.header<{ shadow: boolean }>`
  background: ${theme.colors.bgSecondary};
  height: auto;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 50;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  & button {
    font-size: 16px;
  }

  & > div {
    max-width: 1360px;
    height: ${(props) => props.theme.sizes.headerHeight};
    margin: 0 auto;
    padding: 0 60px;
    @media (max-width: 922px) {
      padding: 0 40px;
    }
    @media (max-width: 880px) {
      padding: 0 20px;
    }
    @media (max-width: 845px) {
      height: ${(props) => props.theme.sizes.mobileHeaderHeight};
    }
  }

  & li a.active {
    color: ${(props) => props.theme.colors.text};
  }

  & > div > nav > div {
    display: flex;
    height: 100%;
    align-items: center;
  }

  & nav {
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & button:not(:nth-last-child(1)) {
      margin-right: 16px;
    }

    & ul {
      display: flex;
      list-style: none;
      margin: 0;

      & li a {
        font-size: 16px;
        font-weight: 500;
        color: ${theme.colors.textGray};
      }
    }
  }
`;

export const ListWrapper = styled.ul`
  display: flex;
  justify-content: space-between; /* Spread items evenly */
  align-items: center; /* Vertically align items */
  padding: 0; /* Reset padding */
  list-style: none; /* Remove list bullets */
`;

export const MenuItem = styled.li`
  flex-grow: 1; /* Allow items to grow and take equal space */
  min-width: 50px;
  &:not(:last-child) {
    margin-right: 30px;
  }
  a:hover {
    color: ${theme.colors.text};
  }
`;

export const MenuWrapper = styled.div`
  position: relative;
  z-index: 30;
  & > div {
    position: absolute;
    height: 300px;
    width: 300px;
    right: -100%;
    top: 0;
  }
`;

export const ReducedMenu = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  & svg {
    z-index: 20;
  }
`;

export const PersonIcon = styled(Person)`
  cursor: pointer;
  color: ${theme.colors.text};
`;

export const MobileLogoLink = styled(NavLink)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
