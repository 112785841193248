import { FC, HTMLAttributes, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
// @ts-ignore
import ReactCountryFlag from "react-country-flag";

import { Label } from "../generic";
import { FlexBox, StyledBlock } from "../containers";
import { ArrowDown } from "components/PopupMenu/styles";
import {
  ContainerSelectedValue,
  CountryName,
  ItemLang,
  PopupSelect,
  StyleFlag
} from "./styles";
import useOnClickOutside from "utils/hooks/useOnClickOutside";
import useHover from "utils/hooks/useHover";
import { useLocation, useNavigate } from "react-router-dom";

interface ISelectFlag extends HTMLAttributes<HTMLDivElement> {
  title?: string;
  items: string[];
  initial?: string;
  placeholder?: string;
  p?: string;
}

const SelectLanguage: FC<ISelectFlag & { showCountryName: boolean }> = ({
  title,
  items = [],
  initial = "GB",
  placeholder,
  p,
  showCountryName = false,
  ...props
}) => {
  const { t, i18n } = useTranslation("header");
  const [show, setShow] = useState(false);

  // const [select, setSelect] = useState<string>(
  //   i18n.language.toUpperCase() || items[0]
  // );

  const select = i18n.language.toUpperCase() || items[0];

  const navigate = useNavigate();
  const location = useLocation();

  const [hoverRef, isHovered] = useHover<HTMLDivElement>();
  const ref = useRef(null);
  useOnClickOutside(ref, () => !isHovered && setShow(false));

  const changeLanguage = (flag: string): void => {
    setShow(!show);
    const path = `/${flag.toLowerCase()}/${location.pathname.slice(4)}${
      location.search
    }`;
    navigate(path, { replace: true });
    i18n.changeLanguage(flag.toLocaleLowerCase());
  };

  return (
    <StyledBlock w="3.5em" style={{ textAlign: "start" }} p={p} {...props}>
      {title && <Label bgColor="transparent">{title}</Label>}
      <StyledBlock onClick={() => setShow(!show)} ref={hoverRef}>
        <ContainerSelectedValue>
          {select.length === 0 ? (
            "Choose language ..."
          ) : (
            <>
              <ReactCountryFlag
                alt=""
                countryCode={select === "GB" ? "GB" : select}
                svg
                style={StyleFlag}
              />
              <ArrowDown size="1em" up={show} style={{ padding: 0 }} />
            </>
          )}
          <CountryName showCountryName={showCountryName}>
            {t(`${select === "GB" ? "EN" : select}`)}
          </CountryName>
        </ContainerSelectedValue>
      </StyledBlock>
      {show && (
        <PopupSelect className={show ? "panel active" : "panel"} ref={ref}>
          <FlexBox direction="column" align="flex-start">
            {items &&
              items?.map((item) => (
                <ItemLang
                  onClick={() => changeLanguage(item)}
                  className={item === select ? "active" : ""}
                  key={item}
                >
                  <ReactCountryFlag
                    alt=""
                    countryCode={item}
                    svg
                    key={item}
                    style={StyleFlag}
                  />
                  {t(`${item === "GB" ? "EN" : item}`)}
                </ItemLang>
              ))}
          </FlexBox>
        </PopupSelect>
      )}
    </StyledBlock>
  );
};

export default SelectLanguage;
