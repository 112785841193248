import styled, { css } from "styled-components/macro";

import { PatchCheckFill } from "@styled-icons/bootstrap/PatchCheckFill";
import { DefaultFont } from "../../styles/fonts";

export const MenuContainer = styled.div`
  flex-grow: 1;
`;

export const ToggleButton = styled.button`
  min-width: 16px;
  height: 100%;
  border: none;
  border-radius: 0;
  background: ${(p) => p.theme.colors.bgSecondary};
  display: flex;
  align-items: start;
  justify-content: center;
  padding-top: 40px;
  cursor: pointer;
`;

export const VerificationIcon = styled(PatchCheckFill)`
  path {
    fill: ${(p) => p.theme.colors.primary};
  }
`;

export const MenuItemLabel = styled.div``;

export const MenuItem = styled.div<{
  $isActive: boolean;
  $isCollapsed?: boolean;
}>`
  display: flex;
  gap: 10px;
  /* padding: 8px 16px; */
  padding: ${(props) =>
    props.$isCollapsed
      ? "8px 8px"
      : "8px 16px"}; /* Conditionally set padding */
  align-items: center;
  cursor: pointer;
  border-radius: 8px;

  ${DefaultFont};
  font-size: 16px;

  color: ${(p) =>
    p.$isActive ? p.theme.colors.text : p.theme.colors.textGray};
  opacity: ${(p) => (p.$isActive ? 1 : 0.6)};

  background-color: ${(p) =>
    p.$isActive ? p.theme.colors.bgSecondary : "white"};

  &:hover {
    background-color: ${(p) => p.theme.colors.bgSecondary};
  }

  ${MenuItemLabel} {
    display: ${(p) => (p.$isCollapsed ? "none" : "block")};
  }
`;
export const MenuItemIcon = styled.div`
  display: flex;
  align-items: center;
  svg {
    width: 24px;
    height: 24px;
  }
`;

export const MenuItemCounter = styled.div<{
  $isCollapsed?: boolean;
}>`
  font-size: 12px;
  background: white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  padding: ${(props) => (props.$isCollapsed ? "0px" : "2px 12px")};
`;

export const MenuRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const Padding = styled.div`
  flex-grow: 1;
`;

export const Root = styled.div<{
  isCollapsed: boolean;
  isCollapsedForSmallScreen: boolean;
}>`
  width: ${(p) => (p.isCollapsed ? "auto" : "250px")};
  background: white;
  height: 100%;
  display: inline-flex;

  ${MenuContainer} {
    padding: ${(p) => (p.isCollapsed ? "16px 6px" : "16px")};
  }

  ${(p) =>
    p.isCollapsedForSmallScreen
      ? css`
          ${ToggleButton} {
            width: 2px;
            svg {
              display: none;
            }
          }
        `
      : ""}

  @media ${(props) => props.theme.media.leftmenu} {
    display: none;
  }
`;
