import { FC, Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, NavLink } from "react-router-dom";
import { useContextHospi } from "context/ContextHospi";
import {
  HOST_HOUSE_WIZARD_ROUTE,
  HOST_WIZARD_ROUTE,
  LOGIN_ROUTE,
  REGISTER_ROUTE,
  STUDENT_WIZARD_ROUTE
} from "utils/constants";
import { Item, StyledMenu } from "./styles";
import { useChat } from "../../../context/ContextChat/ContextChat";
import { useIsHost } from "../../../utils/helpers";
import { isChatPath, useGetProfileMenuRoutes } from "../../Menu/utils";

const ProfileMenu: FC<{
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}> = ({ open, setOpen }) => {
  const { t, i18n } = useTranslation("header");
  const {
    cognitoUser,
    userProfile,
    currentUserName,
    logOut,
    notFinishedRequiredHouseWizard
  } = useContextHospi();

  const isHost = useIsHost();

  const {
    state: { chatCounters }
  } = useChat();

  const unreadCount = isHost
    ? (chatCounters?.requested ?? 0) + (chatCounters?.unread_accepted ?? 0)
    : chatCounters?.unread_accepted ?? 0;

  let menuRoutes = useGetProfileMenuRoutes();
  return (
    <StyledMenu open={open}>
      {!cognitoUser ? (
        <>
          <Item>
            <NavLink
              to={generatePath(LOGIN_ROUTE, {
                lng: i18n.language
              })}
              onClick={() => setOpen((prev) => !prev)}
            >
              {t("menu.signIn")}
            </NavLink>
          </Item>
          <Item>
            <NavLink
              to={generatePath(REGISTER_ROUTE, {
                lng: i18n.language
              })}
              onClick={() => setOpen((prev) => !prev)}
            >
              {t("menu.signUp")}
            </NavLink>
          </Item>
        </>
      ) : (
        <>
          <Item style={{ cursor: "default" }}>
            <p style={{ margin: 0 }}>{t(`subMenu.signedIn`)}</p>
            <p>{currentUserName}</p>
          </Item>
          <hr />
          {userProfile ? (
            <>
              {menuRoutes.map((item) => (
                <Item key={item.path}>
                  <NavLink
                    to={generatePath(item.path, {
                      lng: i18n.language,
                      id: isChatPath(item.path) ? "all" : ""
                    })}
                    onClick={() => {
                      setOpen((prev) => !prev);
                    }}
                  >
                    <span style={{ position: "relative" }}>
                      {t(`subMenu.${item.keyLocalize}`)}{" "}
                      {isChatPath(item.path) && unreadCount > 0
                        ? ` (${unreadCount})`
                        : ""}
                    </span>
                  </NavLink>
                </Item>
              ))}
              {notFinishedRequiredHouseWizard ? (
                <Item>
                  <NavLink
                    to={generatePath(HOST_HOUSE_WIZARD_ROUTE, {
                      lng: i18n.language,
                      houseId: notFinishedRequiredHouseWizard.house_id,
                      wizardType: notFinishedRequiredHouseWizard.wizard_type
                    })}
                  >
                    <span style={{ position: "relative" }}>
                      {t(`subMenu.finishHouseWizard`)}
                    </span>
                  </NavLink>
                </Item>
              ) : null}
            </>
          ) : (
            <Item>
              <NavLink
                to={generatePath(
                  cognitoUser.role === "student"
                    ? STUDENT_WIZARD_ROUTE
                    : HOST_WIZARD_ROUTE,
                  {
                    lng: i18n.language
                  }
                )}
                onClick={() => setOpen((prev) => !prev)}
              >
                {t(`subMenu.finishUserWizard`)}
              </NavLink>
            </Item>
          )}
          <hr />
          <Item
            onClick={() => {
              setOpen((prev) => !prev);
              logOut();
            }}
          >
            {t(`subMenu.signOut`)}
          </Item>
        </>
      )}
    </StyledMenu>
  );
};
export default ProfileMenu;
