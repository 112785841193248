import { Auth, Hub } from "aws-amplify";
import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import { setUser } from "@sentry/react";

import {
  ICognitoUser,
  IPerson,
  RoleUser,
  TCustomOAuthState
} from "../interfaces/interfaces";
import {
  useLocation,
  useNavigate,
  matchPath,
  generatePath
} from "react-router-dom";
import {
  HOST_HOUSE_WIZARD_ROUTE,
  HOST_PROFILE_EDIT_FIRST_ROUTE,
  HOST_PROFILE_VIEW_ROUTE,
  HOST_WIZARD_ROUTE,
  LOGIN_ROUTE,
  STUDENT_PROFILE_EDIT_FIRST_ROUTE,
  STUDENT_PROFILE_VIEW_ROUTE,
  STUDENT_WIZARD_ROUTE
} from "utils/constants";
import {
  clearLocalStorage,
  createCognitoUserFromAttributes
} from "../utils/utilAmplify";
import { publicRoutes, publicRoutesHospi } from "../routes";
import queryClient from "../stores/queryClient";
import { UserAPI } from "../API/userApi";
import { generateErrorResponse } from "../utils/helpers";
import { HouseAPI } from "../API/houseApi";
import { IWizardHouseStateShort } from "../components/Wizard/interfaces";

interface IContextHospi {
  cognitoUser: ICognitoUser | null;
  currentUserId: string | undefined;
  currentUserName: string | undefined;
  currentUserEmail: string | undefined;
  currentUserRole: RoleUser | undefined;
  isLoadingCognitoUser: boolean;
  isLoadingCurrentUser: boolean | "";
  userProfile: IPerson | undefined;
  updateProfile: Dispatch<SetStateAction<IPerson | undefined>>;
  signUp: (
    email: string,
    password: string,
    role: string
  ) => Promise<{ id: string }>;
  signIn: (email?: string, password?: string) => Promise<ICognitoUser>;
  forgotPassword: (email: string) => Promise<string>;
  forgotPasswordSubmit: (
    email: string,
    code: string,
    newPassword: string
  ) => Promise<string>;
  changePassword: (oldPassword: string, newPassword: string) => Promise<string>;
  logOut: (navigateToLogin?: boolean) => void;
  notFinishedRequiredHouseWizard: IWizardHouseStateShort | null;
  cleanNotFinishedRequiredHouseWizard: () => void;
  setNotFinishedRequiredHouseWizard: (wizard: IWizardHouseStateShort) => void;
}

const ContextHospi = createContext<IContextHospi>({
  cognitoUser: null,
  currentUserId: undefined,
  isLoadingCognitoUser: true,
  isLoadingCurrentUser: true,
  currentUserEmail: undefined,
  currentUserRole: undefined,
  currentUserName: "unknown",
  userProfile: undefined,
  updateProfile: () => console.log("change profile"),
  signUp: () => new Promise((r) => r({ id: "" })),
  signIn: () =>
    new Promise((r) =>
      r({
        id: "",
        email: "",
        email_verified: false,
        role: "host",
        locale: "",
        isSocialAuthUser: false
      })
    ),
  forgotPassword: () => new Promise((r) => r("")),
  forgotPasswordSubmit: () => new Promise((r) => r("")),
  changePassword: () => new Promise((r) => r("")),
  logOut: () => console.log("logOut"),
  notFinishedRequiredHouseWizard: null,
  cleanNotFinishedRequiredHouseWizard: () => undefined,
  setNotFinishedRequiredHouseWizard: () => undefined
});

export const useContextHospi = (): IContextHospi => {
  return useContext<IContextHospi>(ContextHospi);
};

const signOut = async () => {
  queryClient.removeQueries();
  clearLocalStorage();
  await Auth.signOut({ global: true });
};

export const ContextHospiProvider: FC<{ children: ReactNode }> = ({
  children
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [cognitoUser, setCognitoUser] = useState<ICognitoUser | null>(null);
  const [profileData, setProfileData] = useState<IPerson | undefined>(
    undefined
  );
  const [isLoadingCognitoUser, setIsLoadingCognitoUser] = useState(true);
  const [isLoadingProfileData, setIsLoadingProfileData] = useState(false);
  const [notFinishedRequiredHouseWizard, setNotFinishedRequiredHouseWizard] =
    useState<IWizardHouseStateShort | null>(null);

  const { i18n } = useTranslation();

  const updateAndSignInSocialAuthUser = useCallback(
    async (user: any, customState: TCustomOAuthState) => {
      const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();
      if (!currentAuthenticatedUser?.attributes?.profile) {
        const { language } = i18n;
        if (customState === "login") {
          navigate(
            generatePath(`${LOGIN_ROUTE}?unknownProfile=True`, {
              lng: language
            })
          );
          return;
        } else {
          await Auth.updateUserAttributes(user, {
            locale: language,
            profile: customState
          });
        }
      }

      await signIn();
    },
    [i18n.language]
  );

  useEffect(() => {
    let socialAuthUser: any = null;
    const hubListener = Hub.listen(
      "auth",
      async ({ payload: { event, data } }) => {
        switch (event) {
          case "cognitoHostedUI":
            socialAuthUser = data;
            break;
          case "customOAuthState":
            if (socialAuthUser && data)
              await updateAndSignInSocialAuthUser(socialAuthUser, data);
            break;
          default:
            break;
        }
      }
    );

    const load = async () => {
      const publicRoutesPaths = [...publicRoutes, ...publicRoutesHospi].map(
        (r) => r.path
      );
      // load namespaces async without waiting result
      // preloading namespaces fixes problem with blinking screen caused by Suspense
      i18n.loadNamespaces([
        "hostrooms",
        "student",
        "footer",
        "contact",
        "host",
        "hostVerify",
        "matches",
        "chats"
      ]);
      setIsLoadingCognitoUser(true);
      try {
        const { attributes } = await Auth.currentAuthenticatedUser();
        const cognitoUser = createCognitoUserFromAttributes(attributes);

        if (cognitoUser.role) {
          setCognitoUser(cognitoUser);
          setIsLoadingProfileData(true);

          try {
            const userProfile = await UserAPI.getUser(cognitoUser.id, {
              showNotificationOnError: false
            });
            setProfileData(userProfile);
          } catch (e) {
            if (
              ![
                ...publicRoutesPaths,
                HOST_WIZARD_ROUTE,
                STUDENT_WIZARD_ROUTE
              ].some((path) => matchPath(path, location.pathname))
            ) {
              const response = generateErrorResponse(e);
              if (response?.status === 404) {
                navigate(
                  generatePath(
                    cognitoUser.role === "student"
                      ? STUDENT_WIZARD_ROUTE
                      : HOST_WIZARD_ROUTE,
                    {
                      lng: i18n.language
                    }
                  )
                );
              } else {
                navigate(`/${i18n.language}/`);
              }
            }
          } finally {
            setIsLoadingProfileData(false);
          }

          if (cognitoUser?.role === "host") {
            HouseAPI.getWizardsStateShort().then((wizards) => {
              const notFinishedWizard = wizards.find(
                (w) =>
                  !w.metadata.finished &&
                  (w.wizard_type === "publish" ||
                    w.wizard_type === "description")
              );
              if (notFinishedWizard) {
                setNotFinishedRequiredHouseWizard(notFinishedWizard);
              }
            });
          }
        }
      } catch (e) {
        if (
          !publicRoutesPaths.some((path) => matchPath(path, location.pathname))
        ) {
          navigate(`/${i18n.language}/`);
        }
      } finally {
        setIsLoadingCognitoUser(false);
      }
    };
    load();

    return () => {
      hubListener();
    };
  }, []);

  const signUp = useCallback(
    async (email: string, password: string, role: string) => {
      let result = await Auth.signUp({
        username: email,
        password: password,
        attributes: {
          profile: role,
          email: email,
          locale: i18n.language
        }
      });

      return {
        id: result.userSub
      };
    },
    [i18n.language]
  );

  const signIn = useCallback(
    async (email?: string, password?: string) => {
      setCognitoUser(null);
      setProfileData(undefined);

      let cognitoUserWithAttributes;
      if (email && password) {
        cognitoUserWithAttributes = await Auth.signIn({
          username: email,
          password: password
        });
      } else {
        // social auth flow
        cognitoUserWithAttributes = await Auth.currentAuthenticatedUser();
      }

      const cognitoUser = createCognitoUserFromAttributes(
        cognitoUserWithAttributes.attributes
      );
      if (!email && !password) {
        // social auth flow
        window.dataLayer.push({
          event: "login",
          user_id: cognitoUser.id,
          user_type: cognitoUser.role
        });
      }

      try {
        const userProfile = await UserAPI.getUser(cognitoUser.id, {
          showNotificationOnError: false
        });
        setCognitoUser(cognitoUser);
        setProfileData(userProfile);

        if (userProfile.is_profile_complete) {
          let redirectUrl: string | null = null;
          try {
            // E.g. of URL redirect param:
            // `http://localhost:3000/gb/login?redirect=room-info/${id}`
            if (sessionStorage.getItem("redirectUrl")) {
              redirectUrl = sessionStorage.getItem("redirectUrl");
            }
          } catch (err) {
            // E.g. sessionStorage may throw QuotaExceededError if is full
            console.log("sessionStorage error", err);
          }
          if (redirectUrl) {
            navigate(
              generatePath("/:lng/" + redirectUrl, {
                lng: i18n.language
              })
            );
            sessionStorage.removeItem("redirectUrl"); // Clean up
          } else {
            navigate(
              generatePath(
                userProfile.user_type === "student"
                  ? STUDENT_PROFILE_VIEW_ROUTE
                  : HOST_PROFILE_VIEW_ROUTE,
                {
                  lng: i18n.language
                }
              )
            );
          }
        } else {
          navigate(
            generatePath(
              userProfile.user_type === "student"
                ? STUDENT_PROFILE_EDIT_FIRST_ROUTE
                : HOST_PROFILE_EDIT_FIRST_ROUTE,
              {
                lng: i18n.language
              }
            )
          );
        }

        // do async check for host wizards
        // to make login process more fast, in most cases initial wizards will be finished
        // and there will not be redirecting
        if (cognitoUser?.role === "host") {
          HouseAPI.getWizardsStateShort().then((wizards) => {
            const notFinishedWizard = wizards.find(
              (w) =>
                !w.metadata.finished &&
                (w.wizard_type === "publish" || w.wizard_type === "description")
            );
            if (notFinishedWizard) {
              setNotFinishedRequiredHouseWizard(notFinishedWizard);
              navigate(
                generatePath(HOST_HOUSE_WIZARD_ROUTE, {
                  houseId: notFinishedWizard.house_id,
                  wizardType: notFinishedWizard.wizard_type,
                  lng: i18n.language
                })
              );
            }
          });
        }
      } catch (error) {
        setCognitoUser(cognitoUser);
        setProfileData(undefined);

        const response = generateErrorResponse(error);
        if (response?.status === 404) {
          navigate(
            generatePath(
              cognitoUser.role === "student"
                ? STUDENT_WIZARD_ROUTE
                : HOST_WIZARD_ROUTE,
              {
                lng: i18n.language
              }
            )
          );
        } else {
          signOut().then(() => {
            if (location.pathname === LOGIN_ROUTE) {
              navigate(`/${i18n.language}/`);
            }
          });
        }
      }

      return cognitoUser;
    },
    [i18n.language, location.pathname, navigate]
  );

  const forgotPassword = useCallback((email: string) => {
    setCognitoUser(null);
    return Auth.forgotPassword(email);
  }, []);

  const forgotPasswordSubmit = useCallback(
    (email: string, code: string, password: string) => {
      setCognitoUser(null);
      return Auth.forgotPasswordSubmit(email, code, password);
    },
    []
  );

  const changePassword = useCallback(
    async (oldPassword: string, newPassword: string) => {
      let user = await Auth.currentAuthenticatedUser();
      return Auth.changePassword(user, oldPassword, newPassword);
    },
    []
  );

  const logOut = useCallback(
    (navigateToLogin: boolean = true) => {
      signOut().then(() => {
        setCognitoUser(null);
        setProfileData(undefined);
        if (navigateToLogin) {
          navigate(`/${i18n.language}/`);
        }
      });
    },
    [i18n.language, navigate]
  );

  useEffect(() => {
    if (!cognitoUser) {
      setUser(null);
      return;
    }
    setUser({
      id: cognitoUser.id,
      email: cognitoUser.email
    });
  }, [cognitoUser]);

  return (
    <ContextHospi.Provider
      value={{
        cognitoUser,
        currentUserId: cognitoUser?.id,
        currentUserEmail: cognitoUser?.email,
        currentUserRole: cognitoUser?.role,
        currentUserName:
          profileData?.first_name || cognitoUser?.email || "Unknown",
        userProfile: profileData,
        isLoadingCognitoUser,
        isLoadingCurrentUser: isLoadingProfileData,
        updateProfile: setProfileData,
        signUp,
        signIn,
        forgotPassword,
        forgotPasswordSubmit,
        changePassword,
        logOut,
        notFinishedRequiredHouseWizard: notFinishedRequiredHouseWizard,
        cleanNotFinishedRequiredHouseWizard: useCallback(() => {
          setNotFinishedRequiredHouseWizard(null);
        }, []),
        setNotFinishedRequiredHouseWizard
      }}
    >
      {children}
    </ContextHospi.Provider>
  );
};
