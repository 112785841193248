import { useCallback, useState } from "react";

export const useMenuCollapsedState = () => {
  const [isCollapsed, setIsCollapsed] = useState(() => {
    return window.localStorage.getItem("isMenuCollapsed") === "true";
  });
  const setIsCollapsedExternal = useCallback((collapsed: boolean) => {
    window.localStorage.setItem(
      "isMenuCollapsed",
      collapsed ? "true" : "false"
    );
    setIsCollapsed(collapsed);
  }, []);

  return {
    isCollapsed,
    setIsCollapsed: setIsCollapsedExternal
  };
};
