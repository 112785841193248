import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  IMatch,
  IBaseMatchData,
  ICancelMatchData,
  IUpdateCheckoutDatePayload,
  TMatchData
} from "../../interfaces/interfaces";
import { HostAPI } from "../hostApi";
import { getMatchQueryKey } from "./studentApiQueries";
import { getHouseQueryKey, getHousesQueryKey } from "./houseApiQueries";
import { useContextHospi } from "../../context/ContextHospi";
import { MatchStatusEnum } from "../../utils/constants";

export const useSendInvite = () => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    unknown,
    Required<
      Pick<
        IMatch,
        | "host_id"
        | "house_id"
        | "student_id"
        | "student_check_in_date"
        | "student_check_out_date"
      >
    >
  >({
    mutationFn: (payload) => HostAPI.invite(payload),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: getMatchQueryKey({
          studentId: variables.student_id,
          hostId: variables.host_id,
          houseId: variables.house_id
        })
      });
    }
  });
};
export const useCancelInvite = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, unknown, IBaseMatchData>({
    mutationFn: (payload) => HostAPI.cancelInvite(payload),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: getMatchQueryKey({
          studentId: variables.student_id,
          hostId: variables.host_id,
          houseId: variables.house_id
        })
      });
    }
  });
};
export const useCancelMatch = () => {
  const queryClient = useQueryClient();
  const { cognitoUser } = useContextHospi();

  return useMutation<unknown, unknown, ICancelMatchData>({
    mutationFn: (payload) => HostAPI.cancelMatch(payload),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: getMatchQueryKey({
          studentId: variables.student_id,
          hostId: variables.host_id,
          houseId: variables.house_id
        })
      });
      queryClient.invalidateQueries({
        queryKey: getHostMatchesQueryKey({ userId: variables.host_id })
      });
      queryClient.invalidateQueries({
        queryKey: getHouseQueryKey(variables.house_id)
      });
      queryClient.invalidateQueries({
        queryKey: getHousesQueryKey(cognitoUser?.id!)
      });
    }
  });
};

export const getHostMatchesQueryKey = ({ userId }: { userId?: string }) => [
  "hostMatches",
  userId
];

export const useUpdateOfferedDates = (matchStatus?: MatchStatusEnum) => {
  const queryClient = useQueryClient();
  return useMutation<
    unknown,
    unknown,
    Required<
      Pick<
        IMatch,
        | "host_id"
        | "house_id"
        | "student_id"
        | "student_check_in_date"
        | "student_check_out_date"
      >
    >
  >({
    mutationFn: (payload) => HostAPI.updateOfferedDates(payload),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: getMatchQueryKey({
          studentId: variables.student_id,
          hostId: variables.host_id,
          houseId: variables.house_id
        })
      });
      if (!!matchStatus && matchStatus !== MatchStatusEnum.INVITED) {
        queryClient.setQueryData<TMatchData[]>(
          getHostMatchesQueryKey({ userId: variables.host_id }),
          (oldData) => {
            if (oldData) {
              return oldData.map((m) => {
                if (
                  m.student_id === variables.student_id &&
                  m.house_id === variables.house_id &&
                  m.host_id === variables.host_id
                ) {
                  return {
                    ...m,
                    check_in_date: variables.student_check_in_date,
                    check_out_date: variables.student_check_out_date
                  };
                } else return m;
              });
            }
          }
        );
      }
    }
  });
};

export const useGetHostMatches = (userId?: string, enabled: boolean = true) => {
  return useQuery(
    getHostMatchesQueryKey({ userId }),
    () => {
      if (!userId) return;
      return HostAPI.getHostMatches(userId);
    },
    {
      enabled: Boolean(userId) && enabled !== false
    }
  );
};

export const useGetHostMatch = (
  studentId?: string,
  hostId?: string,
  houseId?: string,
  enabled?: boolean
) => {
  return useQuery(
    getMatchQueryKey({ studentId, hostId, houseId }),
    () => {
      if (!studentId || !hostId || !houseId) return;

      return HostAPI.getMatch(studentId, hostId, houseId, {
        showNotificationOnError: false
      });
    },
    {
      enabled: Boolean(studentId && hostId && houseId) && !!enabled
    }
  );
};

export const useUpdateCheckoutDate = () => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    unknown,
    { payload: IUpdateCheckoutDatePayload; isDateInPast: boolean }
  >({
    mutationFn: ({ payload }) => HostAPI.updateCheckoutDate(payload),
    onSuccess: (_, { payload, isDateInPast }) => {
      queryClient.invalidateQueries({
        queryKey: getMatchQueryKey({
          studentId: payload.student_id,
          hostId: payload.host_id,
          houseId: payload.house_id
        })
      });

      queryClient.setQueryData<TMatchData[]>(
        getHostMatchesQueryKey({ userId: payload.host_id }),
        (oldData) => {
          if (oldData) {
            return oldData.map((m) => {
              if (
                m.student_id === payload.student_id &&
                m.house_id === payload.house_id &&
                m.host_id === payload.host_id
              ) {
                return {
                  ...m,
                  check_out_date: payload.new_check_out_date,
                  match_status: isDateInPast
                    ? MatchStatusEnum.ARCHIVED
                    : m.match_status,
                  checkout_date_actor: "host"
                };
              } else return m;
            });
          }
        }
      );
    }
  });
};
