import { useTranslation } from "react-i18next";
import { lazy, useState } from "react";
import { Wrapper } from "./styles";
import { useContextHospi } from "../../context/ContextHospi";
import { Button } from "../generic";
import { createPortal } from "react-dom";

const HostVerificationModalLazy = lazy(
  () => import("../HostVerificationModal")
);

export const VerificationBanner = () => {
  const { userProfile } = useContextHospi();
  const [show] = useState<boolean>(
    userProfile ? userProfile.verification_status !== "verified" : false
  );
  const [requested, setRequested] = useState<boolean>(
    userProfile ? userProfile.verification_status === "verifying" : false
  );
  const { t } = useTranslation("host");
  const [showModal, setShowModal] = useState(false);

  interface IPortal {
    container: HTMLElement;
  }

  const ModalPortal = (props: IPortal) => {
    return createPortal(
      <HostVerificationModalLazy
        show={true}
        onClose={() => {
          setShowModal(false);
        }}
        onValidationRequested={() => {
          setRequested(true);
          setShowModal(false);
        }}
      />,
      props.container
    );
  };

  return (
    <>
      <Wrapper show={show}>
        {requested ? (
          <>
            <div>
              <p>{t("popupVerification.requested")}</p>
            </div>
          </>
        ) : (
          <>
            <div>
              <p>{t("popupVerification.text")}</p>
              <Button
                main
                onClick={() => {
                  setShowModal(true);
                }}
              >
                {t("popupVerification.button")}
              </Button>
            </div>
          </>
        )}
      </Wrapper>
      {showModal && (
        <ModalPortal
          container={document.getElementById("root") as HTMLElement}
        />
      )}
    </>
  );
};
