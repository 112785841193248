import styled from "styled-components/macro";

export const ContainerMenu = styled.ul`
  display: grid;
  grid-template-columns: repeat(7, max-content);
  gap: 40px;
  margin: 0;
  align-items: center;
  & a {
    text-decoration: none;
    transition: 0.2s;
    color: ${(props) => props.theme.colors.text};
    &.active {
      color: ${(props) => props.theme.colors.primary};
    }
  }
`;

export const Item = styled.li<{ popup?: boolean }>`
  font-size: ${(props) =>
    props.popup ? props.theme.font.size : props.theme.font.size};
  background-color: transparent;
  font-weight: 400;
  cursor: pointer;
  padding: 0;
  list-style-type: none;
  color: ${(props) => props.theme.colors.text};
  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }
  &.logout {
    padding: 8px 16px;
  }
`;

export const ConteinerPopup = styled.ul`
  display: flex;
  flex-direction: column;
`;
export const ItemUser = styled.li`
  padding: 16px 0 8px 16px;
  list-style-type: none;
  font-size: ${(props) => props.theme.font.size};
  color: ${(props) => props.theme.colors.text};
  & > p {
    margin: 0;
  }
  & > p:last-child {
    word-break: break-all;
    font-weight: 600;
  }
`;
