import {
  ChatStatus,
  IChatUserCounters,
  IFulfilledChat,
  MessageOperation,
  TMessage,
  MobileViewMode
} from "interfaces/interfacesChat";
import { parseServerDateTime } from "utils/time";
import {
  getChatMyIdField,
  getChatReadAtField,
  getChatRemoveByField
} from "utils/chat";
import { createReducer } from "utils/reduxUtils";
import { prepareChatMessages, sortChats } from "./utils";

export interface IChatState {
  userRole: string | null;
  chats: IFulfilledChat[];
  filterByChatStatus: ChatStatus;
  isLoadingChats: boolean;
  activeChatId: string | null;
  activeChatMessages: {
    messages: TMessage[];
    isFirstPage: boolean;
  };
  isLoadingActiveChatMessages: boolean;
  isLoadingMoreChatMessages: boolean;
  chatCounters: IChatUserCounters | null;
  chatsFilteredByTab: IFulfilledChat[];
  mobileViewMode: MobileViewMode | null;
}

export enum ChatActions {
  SET_CHATS_IS_LOADING = "SET_CHATS_IS_LOADING",
  SET_CHATS = "SET_CHATS",
  SET_CHAT_UNREAD_COUNT = "SET_CHAT_UNREAD_COUNT",

  SET_ACTIVE_CHAT_ID = "SET_ACTIVE_CHAT_ID",
  SET_ACTIVE_CHAT_MESSAGES = "SET_ACTIVE_CHAT_MESSAGES",
  SET_ACTIVE_CHAT_MESSAGES_IS_LOADING = "SET_ACTIVE_CHAT_MESSAGES_IS_LOADING",

  ADD_ACTIVE_CHAT_MESSAGES = "ADD_ACTIVE_CHAT_MESSAGES",
  SET_MORE_CHAT_MESSAGES_IS_LOADING = "SET_MORE_CHAT_MESSAGES_IS_LOADING",

  SET_CHAT_COUNTERS = "SET_CHAT_COUNTERS",
  SET_CHATS_FILTERED_BY_TAB = "SET_CHATS_FILTERED_BY_TAB",

  ADD_TEMP_MESSAGE = "ADD_TEMP_MESSAGE",
  PROCESS_NEW_MESSAGE = "PROCESS_NEW_MESSAGE",
  PROCESS_READ_MESSAGE = "PROCESS_READ_MESSAGE",
  UPDATE_MESSAGE = "UPDATE_MESSAGE",
  CHANGE_MESSAGE_OPERATION = "CHANGE_MESSAGE_OPERATION",
  REMOVE_MESSAGE = "REMOVE_MESSAGE",

  ADD_CHAT = "ADD_CHAT",
  REMOVE_CHAT = "REMOVE_CHAT",
  UNREMOVE_CHAT = "UNREMOVE_CHAT",
  ACCEPT_CHAT = "ACCEPT_CHAT",
  UPDATE_CHAT = "UPDATE_CHAT",
  DELETE_CHAT = "DELETE_CHAT",

  CHANGE_MOBILE_VIEW_MODE = "CHANGE_MOBILE_VIEW_MODE",
  SET_USER_ROLE = "SET_USER_ROLE",
  RESET = "RESET"
}

export type TChatAction = { type: ChatActions; payload: any };

export const CHAT_INITIAL_STATE: IChatState = {
  userRole: null,
  chats: [],
  filterByChatStatus: ChatStatus.REQUESTED,
  isLoadingChats: false,
  activeChatId: null,
  activeChatMessages: {
    messages: [],
    isFirstPage: false
  },
  isLoadingActiveChatMessages: false,
  isLoadingMoreChatMessages: false,
  chatCounters: null,
  chatsFilteredByTab: [],
  mobileViewMode: null
};

type GenericChatAction<P> = Omit<TChatAction, "payload"> & {
  payload: P;
};

type TActionHandler<Payload> = (
  state: IChatState,
  action: GenericChatAction<Payload>
) => IChatState;

const setUserRole: TActionHandler<string> = (state, action) => {
  return {
    ...state,
    userRole: action.payload
  };
};

const setChats: TActionHandler<IFulfilledChat[]> = (state, action) => {
  const chats = sortChats(action.payload) ?? [];

  let declinedChatsCount = 0;
  if (state.userRole === "student" && chats.length > 0) {
    declinedChatsCount = chats.filter(
      (chat) =>
        chat.chat_status === ChatStatus.REQUESTED && chat.removed_by_host
    ).length;
  }

  return {
    ...state,
    chats,
    chatCounters:
      typeof state.chatCounters?.requested === "number"
        ? {
            ...state.chatCounters,
            requested: state.chatCounters.requested - declinedChatsCount
          }
        : state.chatCounters
  };
};

const setChatUnreadCount: TActionHandler<{ chatId: string; count: number }> = (
  state,
  action
) => {
  const chat = state.chats.find((c) => c.chat_id === action.payload.chatId);

  let chatCounters = { ...state.chatCounters } as IChatUserCounters;
  if (
    action.payload.count === 0 &&
    chat?.request_user_unread_count !== 0 &&
    chat?.chat_status === ChatStatus.ACCEPTED
  ) {
    chatCounters.unread_accepted--;
  }

  return {
    ...state,
    chatCounters,
    chats: state.chats.map((chat) => {
      if (chat.chat_id === action.payload.chatId) {
        return {
          ...chat,
          request_user_unread_count: action.payload.count
        };
      }
      return chat;
    })
  };
};

const setChatsIsLoading: TActionHandler<boolean> = (state, action) => {
  return {
    ...state,
    isLoadingChats: action.payload
  };
};

const setActiveChatId: TActionHandler<string> = (state, action) => {
  return {
    ...state,
    activeChatId: action.payload
  };
};

const setActiveChatMessagesIsLoading: TActionHandler<boolean> = (
  state,
  action
) => {
  return {
    ...state,
    isLoadingActiveChatMessages: action.payload
  };
};
const setMoreChatMessagesIsLoading: TActionHandler<boolean> = (
  state,
  action
) => {
  return {
    ...state,
    isLoadingMoreChatMessages: action.payload
  };
};

const setChatCounters: TActionHandler<IChatUserCounters | null> = (
  state,
  action
) => {
  return {
    ...state,
    chatCounters: action.payload
  };
};

const setChatsFilteredByTab: TActionHandler<IFulfilledChat[]> = (
  state,
  action
) => {
  return {
    ...state,
    chatsFilteredByTab: action.payload
  };
};

const setActiveChatMessages: TActionHandler<TMessage[]> = (state, action) => {
  return {
    ...state,
    activeChatMessages: {
      messages: prepareChatMessages(action.payload as TMessage[]),
      isFirstPage: true
    }
  };
};
const addActiveChatMessages: TActionHandler<{
  chatId: string;
  messages: TMessage[];
}> = (state, action) => {
  if (state.activeChatId !== action.payload.chatId) {
    return state;
  }

  return {
    ...state,
    activeChatMessages: {
      messages: prepareChatMessages([
        ...state.activeChatMessages.messages,
        ...(action.payload.messages as TMessage[])
      ]),
      isFirstPage: false
    }
  };
};

const addTempMessage: TActionHandler<TMessage> = (state, action) => {
  const newMessage = action.payload;

  return {
    ...state,
    chats: sortChats(
      state.chats.map((chat) => {
        if (chat.chat_id === newMessage.chat_id) {
          return {
            ...chat,
            most_recent_msg: newMessage,
            updated_at: newMessage.created_at
          };
        }
        return chat;
      })
    ),
    activeChatMessages:
      state.activeChatId === newMessage.chat_id
        ? {
            messages: [...state.activeChatMessages.messages, newMessage],
            isFirstPage: state.activeChatMessages.isFirstPage
          }
        : state.activeChatMessages
  };
};

const processNewMessage: TActionHandler<TMessage> = (state, action) => {
  const newMessage = action.payload as TMessage;
  const messages = state.activeChatMessages.messages;

  let updateMessageIndex: number = -1;
  let updatedMessages = state.activeChatMessages;

  if (state.activeChatId === newMessage.chat_id) {
    //check if message is temporary and needs to be replaced
    if (newMessage.trace_id) {
      updateMessageIndex = messages.findIndex(
        (m) =>
          m.trace_id === newMessage.trace_id &&
          m.operation === MessageOperation.CREATING
      );
    }
    if (updateMessageIndex === -1) {
      //try to find existed message
      updateMessageIndex = messages.findIndex(
        (m) => m.created_at === newMessage.created_at
      );
    }

    updatedMessages = {
      messages: prepareChatMessages(
        updateMessageIndex !== -1
          ? messages.map((m, index) => {
              if (index === updateMessageIndex) {
                return newMessage;
              }
              return m;
            })
          : [...messages, newMessage]
      ),
      isFirstPage: state.activeChatMessages.isFirstPage
    };
  }

  let chatCounters = { ...state.chatCounters } as IChatUserCounters;

  const chat = state.chats.find((chat) => chat.chat_id === newMessage.chat_id);
  if (chat) {
    const isMyMessages =
      newMessage.author_id === chat[getChatMyIdField(state.userRole!)];
    if (
      !isMyMessages &&
      state.activeChatId !== chat.chat_id &&
      chat.request_user_unread_count === 0
    ) {
      chatCounters.unread_accepted++;
    }
  }

  return {
    ...state,
    chatCounters: chatCounters,
    chats: sortChats(
      state.chats.map((chat) => {
        if (chat.chat_id === newMessage.chat_id) {
          const latestMessage =
            !chat.most_recent_msg ||
            parseServerDateTime(newMessage.created_at) >=
              parseServerDateTime(chat.most_recent_msg?.created_at)
              ? newMessage
              : chat.most_recent_msg;

          const chatUpdatedAt =
            parseServerDateTime(latestMessage.created_at) >
            parseServerDateTime(chat.updated_at)
              ? latestMessage.created_at
              : chat.updated_at;

          return {
            ...chat,
            request_user_unread_count:
              newMessage.author_id !==
                chat[getChatMyIdField(state.userRole!)] &&
              chat.chat_id !== state.activeChatId
                ? chat.request_user_unread_count + 1
                : chat.request_user_unread_count,
            most_recent_msg: latestMessage,
            updated_at: chatUpdatedAt,
            [getChatReadAtField(state.userRole!)]: chatUpdatedAt
          };
        }
        return chat;
      })
    ),
    activeChatMessages: updatedMessages
  };
};
const processReadMessage: TActionHandler<TMessage> = (state, action) => {
  const newMessage = action.payload;
  const messages = state.activeChatMessages.messages;

  let updateMessageIndex: number = -1;
  let updatedMessages = state.activeChatMessages;

  if (state.activeChatId === newMessage.chat_id) {
    updateMessageIndex = messages.findIndex(
      (m) => m.created_at === newMessage.created_at
    );
    if (updateMessageIndex !== -1) {
      updatedMessages = {
        messages: prepareChatMessages(
          messages.map((m, index) => {
            if (index === updateMessageIndex) {
              return newMessage;
            }
            return m;
          })
        ),
        isFirstPage: state.activeChatMessages.isFirstPage
      };
    }
  }

  return {
    ...state,
    activeChatMessages: updatedMessages
  };
};

const processUpdateMessage: TActionHandler<TMessage> = (state, action) => {
  const newMessage = action.payload;
  const messages = state.activeChatMessages.messages;

  let updateMessageIndex: number = -1;
  let updatedMessages = state.activeChatMessages;

  if (state.activeChatId === newMessage.chat_id) {
    updateMessageIndex = messages.findIndex(
      (m) => m.created_at === newMessage.created_at
    );
    if (updateMessageIndex !== -1) {
      updatedMessages = {
        messages: prepareChatMessages(
          messages.map((m, index) => {
            if (index === updateMessageIndex) {
              return newMessage;
            }
            return m;
          })
        ),
        isFirstPage: state.activeChatMessages.isFirstPage
      };
    }
  }

  return {
    ...state,
    activeChatMessages: updatedMessages,
    chats: sortChats(
      state.chats.map((chat) => {
        if (chat.chat_id === newMessage.chat_id) {
          const latestMessage =
            !chat.most_recent_msg ||
            parseServerDateTime(newMessage.created_at) >=
              parseServerDateTime(chat.most_recent_msg?.created_at)
              ? newMessage
              : chat.most_recent_msg;

          const chatUpdatedAt =
            parseServerDateTime(latestMessage.created_at) >
            parseServerDateTime(chat.updated_at)
              ? latestMessage.created_at
              : chat.updated_at;

          return {
            ...chat,
            most_recent_msg: latestMessage,
            updated_at: chatUpdatedAt,
            [getChatReadAtField(state.userRole!)]: chatUpdatedAt
          };
        }
        return chat;
      })
    )
  };
};

const removeMessage: TActionHandler<TMessage> = (state, action) => {
  const newMessage = action.payload as TMessage;
  const messages = state.activeChatMessages.messages;

  let newMessageIndex: number = -1;
  let updatedMessages = state.activeChatMessages;

  if (state.activeChatId === newMessage.chat_id) {
    newMessageIndex = messages.findIndex(
      (m) => m.created_at === newMessage.created_at
    );
    if (newMessageIndex !== -1) {
      updatedMessages = {
        messages: prepareChatMessages(
          messages.filter((_, index) => {
            return index !== newMessageIndex;
          })
        ),
        isFirstPage: state.activeChatMessages.isFirstPage
      };
    }
  }

  return {
    ...state,
    activeChatMessages: updatedMessages,
    chats: sortChats(
      state.chats.map((chat) => {
        if (chat.chat_id === newMessage.chat_id) {
          const latestMessage =
            chat.most_recent_msg &&
            parseServerDateTime(newMessage.created_at) ===
              parseServerDateTime(chat.most_recent_msg?.created_at)
              ? undefined
              : chat.most_recent_msg;

          return {
            ...chat,
            most_recent_msg: latestMessage
          };
        }
        return chat;
      })
    )
  };
};

const removeChat: TActionHandler<{ chatId: string }> = (state, action) => {
  const chatId = action.payload.chatId;

  let chatCounters = { ...state.chatCounters } as IChatUserCounters;
  const chat = state.chats.find((c) => c.chat_id === chatId);
  if (chat && chatCounters) {
    if (chat.chat_status === ChatStatus.REQUESTED) {
      chatCounters.requested--;
    }
    if (chat.chat_status === ChatStatus.ACCEPTED) {
      chatCounters.accepted--;
      if (chat.request_user_unread_count) {
        chatCounters.unread_accepted--;
      }
    }
  }

  return {
    ...state,
    chatCounters,
    chats: sortChats(
      state.chats.map((chat) => {
        if (chat.chat_id === chatId) {
          return {
            ...chat,
            [getChatRemoveByField(state.userRole!)]: true
          };
        }
        return chat;
      })
    )
  };
};

const unRemoveChat: TActionHandler<{ chatId: string }> = (state, action) => {
  const chatId = action.payload.chatId;

  let chatCounters = { ...state.chatCounters } as IChatUserCounters;
  const chat = state.chats.find((c) => c.chat_id === chatId);
  if (chat && chatCounters) {
    if (chat.chat_status === ChatStatus.REQUESTED) {
      chatCounters.requested++;
    }
    if (chat.chat_status === ChatStatus.ACCEPTED) {
      chatCounters.accepted++;
      if (chat.request_user_unread_count) {
        chatCounters.unread_accepted++;
      }
    }
  }

  return {
    ...state,
    chatCounters,
    chats: sortChats(
      state.chats.map((chat) => {
        if (chat.chat_id === chatId) {
          return {
            ...chat,
            [getChatRemoveByField(state.userRole!)]: false
          };
        }
        return chat;
      })
    )
  };
};

const acceptChat: TActionHandler<{ chatId: string }> = (state, action) => {
  const chatId = action.payload.chatId;

  let chatCounters = { ...state.chatCounters } as IChatUserCounters;
  const chat = state.chats.find((c) => c.chat_id === chatId);

  if (chat && chatCounters) {
    if (!chat[getChatRemoveByField(state.userRole!)]) {
      chatCounters.requested--;
    }
    chatCounters.accepted = chatCounters.accepted++;
    if (chat.request_user_unread_count) {
      chatCounters.unread_accepted++;
    }
  }

  return {
    ...state,
    chatCounters,
    chats: sortChats(
      state.chats.map((chat) => {
        if (chat.chat_id === chatId) {
          return {
            ...chat,
            chat_status: ChatStatus.ACCEPTED
          };
        }
        return chat;
      })
    )
  };
};
const updateChat: TActionHandler<IFulfilledChat> = (state, action) => {
  const updatedChat = action.payload;

  // TODO: update counters

  return {
    ...state,
    chats: sortChats(
      state.chats.map((chat) => {
        if (chat.chat_id === updatedChat.chat_id) {
          return updatedChat;
        }
        return chat;
      })
    )
  };
};
const addChat: TActionHandler<IFulfilledChat> = (state, action) => {
  const newChat = action.payload;

  if (state.chats.find((c) => c.chat_id === newChat.chat_id)) {
    return state;
  }

  let chatCounters = { ...state.chatCounters } as IChatUserCounters;
  if (chatCounters) {
    chatCounters.requested++;
  }

  return {
    ...state,
    chatCounters,
    chats: sortChats([...state.chats, newChat])
  };
};
const deleteChat: TActionHandler<string> = (state, action) => {
  const chatId = action.payload;

  const chat = state.chats.find((c) => c.chat_id === chatId);
  let chatCounters = { ...state.chatCounters } as IChatUserCounters;
  if (chatCounters) {
    if (
      chat?.chat_status === ChatStatus.ACCEPTED &&
      chat.request_user_unread_count > 0
    ) {
      chatCounters.unread_accepted--;
    }
    if (chat?.chat_status === ChatStatus.REQUESTED) {
      chatCounters.requested--;
    }
  }

  return {
    ...state,
    chatCounters,
    chats: sortChats(state.chats.filter((c) => c.chat_id !== chatId))
  };
};

const changeMessageOperation: TActionHandler<{
  chat_id: string;
  created_at: string;
  operation: MessageOperation;
}> = (state, action) => {
  const messages = state.activeChatMessages.messages;

  let updateMessageIndex: number = -1;
  let updatedMessages = state.activeChatMessages;

  if (state.activeChatId === action.payload.chat_id) {
    updateMessageIndex = messages.findIndex(
      (m) => m.created_at === action.payload.created_at
    );
    if (updateMessageIndex !== -1) {
      updatedMessages = {
        messages: prepareChatMessages(
          messages.map((m, index) => {
            if (index === updateMessageIndex) {
              return {
                ...m,
                operation: action.payload.operation
              };
            }
            return m;
          })
        ),
        isFirstPage: state.activeChatMessages.isFirstPage
      };
    }
  }

  return {
    ...state,
    activeChatMessages: updatedMessages
  };
};

const changeMobileViewMode: TActionHandler<MobileViewMode | null> = (
  state,
  action
) => {
  return {
    ...state,
    mobileViewMode: action.payload
  };
};

export const chatReducer = createReducer<IChatState, GenericChatAction<any>>({
  [ChatActions.SET_USER_ROLE]: setUserRole,
  [ChatActions.SET_CHATS]: setChats,
  [ChatActions.SET_CHAT_UNREAD_COUNT]: setChatUnreadCount,
  [ChatActions.SET_CHATS_IS_LOADING]: setChatsIsLoading,
  [ChatActions.SET_ACTIVE_CHAT_ID]: setActiveChatId,
  [ChatActions.SET_ACTIVE_CHAT_MESSAGES_IS_LOADING]:
    setActiveChatMessagesIsLoading,
  [ChatActions.SET_MORE_CHAT_MESSAGES_IS_LOADING]: setMoreChatMessagesIsLoading,
  [ChatActions.SET_CHAT_COUNTERS]: setChatCounters,
  [ChatActions.SET_CHATS_FILTERED_BY_TAB]: setChatsFilteredByTab,
  [ChatActions.SET_ACTIVE_CHAT_MESSAGES]: setActiveChatMessages,
  [ChatActions.ADD_ACTIVE_CHAT_MESSAGES]: addActiveChatMessages,
  [ChatActions.ADD_TEMP_MESSAGE]: addTempMessage,
  [ChatActions.PROCESS_NEW_MESSAGE]: processNewMessage,
  [ChatActions.PROCESS_READ_MESSAGE]: processReadMessage,
  [ChatActions.UPDATE_MESSAGE]: processUpdateMessage,
  [ChatActions.REMOVE_MESSAGE]: removeMessage,
  [ChatActions.REMOVE_CHAT]: removeChat,
  [ChatActions.UNREMOVE_CHAT]: unRemoveChat,
  [ChatActions.ACCEPT_CHAT]: acceptChat,
  [ChatActions.UPDATE_CHAT]: updateChat,
  [ChatActions.ADD_CHAT]: addChat,
  [ChatActions.DELETE_CHAT]: deleteChat,
  [ChatActions.CHANGE_MESSAGE_OPERATION]: changeMessageOperation,
  [ChatActions.CHANGE_MOBILE_VIEW_MODE]: changeMobileViewMode,
  [ChatActions.RESET]: () => CHAT_INITIAL_STATE
});
