import styled from "styled-components/macro";
import { theme } from "../../styles/theme";

export const Wrapper = styled.div<{ show: boolean }>`
  flex-direction: column;
  display: ${({ show }) => (show ? "flex" : "none")};
  top: calc(${(props) => props.theme.sizes.heightHeaderDesktop});
  align-self: flex-end;
  margin-top: 24px;
  width: 100%;
  border-radius: ${(props) => props.theme.sizes.borderRadius};
  border: 1px solid ${(props) => props.theme.colors.border};
  background-color: ${(props) => props.theme.colors.place};
  & > button {
    float: right;
    background-color: transparent;
    border: none;
    align-self: flex-end;
    margin-right: 10px;
    margin-top: 5px;
    font-size: medium;
  }

  & > div {
    padding: 12px 12px 12px 12px;
    & > p {
      color: ${(props) => props.theme.colors.text};
      font-size: ${(props) => props.theme.font.size};
      margin-bottom: 12px;
    }
  }
  & a {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 24px;

    color: ${theme.colors.text}
  }

  }
`;
