import styled from "styled-components/macro";
import Burger from "../Burger";

export const Item = styled.li`
  font-size: 1.5rem;
  font-weight: bold;
  list-style-type: none;
  color: ${(props) => props.theme.colors.text};
  text-decoration: none;
  transition: color 0.3s linear;
  cursor: pointer;

  @media ${(props) => props.theme.media.phone} {
    font-size: 1.5rem;
    text-align: center;
  }
`;

export const ProfileBurger = styled(Burger)<{ rightMenuOpen?: boolean }>`
  z-index: ${(props) => (props.rightMenuOpen ? 10 : 20)}!important;
  position: absolute;
  right: 0;
`;

export const StyledMenu = styled.ul<{ open: boolean }>`
  display: block;
  background: ${(props) => props.theme.colors.place};
  box-shadow: 0px 1px 0px #dcd6ce;
  height: 100vh;
  text-align: left;
  padding: 4rem;
  position: fixed;
  top: 0;
  right: -10%;
  width: 80%;
  overflow-x: hidden;
  overflow-y: auto;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(150%)")};

  & .mobile-lang {
    & span {
      font-size: 18px;
      & img {
        width: 2em;
        height: 2em;
      }
    }
  }

  & > label {
    padding-right: 1rem;
    text-align: end;
    @media ${(props) => props.theme.media.phone} {
      text-align: center;
    }
  }
  & > li:last-child {
    padding-top: 16px;
  }
  & a {
    display: block;
    text-decoration: none;
    cursor: pointer;
    transition: 0.2s;
    transition: color 0.3s linear;
    color: ${(props) => props.theme.colors.text};
    padding: 1.5rem 1rem;
    font-weight: bold;
    &.active {
      background-color: ${(props) => props.theme.colors.borderSecondary};
    }
  }

  @media ${(props) => props.theme.media.phone} {
    width: 120%;
    font-size: 1.5rem;
    text-align: center;
  }
`;
