import { FC, ReactNode } from "react";
import {
  MenuRoot,
  MenuItemIcon,
  MenuItemLabel,
  Padding,
  MenuItemCounter,
  MenuItem
} from "./styles";
import { NavLink } from "react-router-dom";

export type TMenuItem = {
  label: string;
  icon: ReactNode;
  value: string;
  counter?: number;
  [key: string]: any;
};

export const Menu: FC<{
  items: TMenuItem[];
  value?: string;
  isCollapsed?: boolean;
  onItemClick: (item: TMenuItem) => void;
}> = ({ items, value, isCollapsed, onItemClick }) => {
  return (
    <MenuRoot>
      {items.map((item) => (
        <MenuItem
          to={item.value}
          $isActive={item.value === value}
          $isCollapsed={isCollapsed}
          onClick={() => {
            onItemClick(item);
          }}
          key={item.value}
          as={NavLink}
        >
          <MenuItemIcon>{item.icon}</MenuItemIcon>
          <MenuItemLabel>{item.label}</MenuItemLabel>
          <Padding />
          {item.counter ? (
            <MenuItemCounter $isCollapsed={isCollapsed}>
              {item.counter}
            </MenuItemCounter>
          ) : null}
        </MenuItem>
      ))}
    </MenuRoot>
  );
};
