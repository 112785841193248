import { createGlobalStyle } from "styled-components";
import { theme } from "./theme";

export default createGlobalStyle`

* {
    font-family: "Inter", sans-serif;
}

*{
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}

#root {
    margin: 0 auto;
    background-color: ${theme.colors.bgBody};

    font-size: 16px;
}
body {
    overflow: hidden;
    line-height: 1.5715;
}

html {
    scroll-padding-top: 64px;
}

.MarkerClusterer__Marker {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: ${theme.colors.text};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: ${theme.text.family};
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: white;
}

.MarkerClusterer__Marker-active {
    border-radius: 50%;
    background-color: ${theme.colors.bgSecondary};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: ${theme.colors.text};
    border: 5px solid black;
    font-family: ${theme.text.family};
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    width: 36px;
    height: 36px;
}

.MarkerClusterer__Marker-selected {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: ${theme.colors.bgSecondary};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: ${theme.colors.text};
    border: 2px solid ${theme.colors.text};
    font-family: ${theme.text.family};
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
}

fieldset {
  border: none;
}

a {
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color .3s;
}

.wizard-helper-popup {
  .ant-popover-inner {
    padding: 24px;
  }
}

.longOptionsTextSelectPopup.ant-select-dropdown .ant-select-item-option-content {
    @media ${theme.media.phone} {
        white-space: normal;
    }
}
.allScreensLongTextSelectPopup.ant-select-dropdown .ant-select-item-option-content {
    white-space: normal;
}
`;
