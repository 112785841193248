import {
  HOST_PROFILE_VIEW_ROUTE,
  HOST_ROOMS_ROUTE,
  HOST_TENANTS_ROUTE,
  STUDENT_MATCHES_ROUTE,
  STUDENT_ROOMS_ROUTE,
  STUDENT_PROFILE_VIEW_ROUTE,
  STUDENT_CHATS_ROUTE,
  HOST_CHATS_ROUTE
} from "utils/constants";

export const studentMenuRoutes = [
  STUDENT_PROFILE_VIEW_ROUTE,
  STUDENT_ROOMS_ROUTE,
  STUDENT_MATCHES_ROUTE,
  STUDENT_CHATS_ROUTE + "/all"
];

export const hostMenuRoutes = [
  HOST_PROFILE_VIEW_ROUTE,
  HOST_ROOMS_ROUTE,
  HOST_CHATS_ROUTE + "/all",
  HOST_TENANTS_ROUTE
];
