import { DefaultTheme } from "styled-components";

const theme: DefaultTheme = {
  colors: {
    primary: "#EA6B26",
    primaryACB: "#C35113",
    secondary: "#F4EBDA",
    thirdly: "#72310E",
    fourtly: "#FFAB7D",
    text: "#032565",
    textDimmed: "#6F7F9E",
    textMuted: "#978E88",
    border: "#DCD6CE",
    place: "#F6F8FD", // used to be this "#FFFFFF",
    activeRadioBox: "#FFF1D0",
    bgBody: "#F6F8FD", // used to be this: "#FFFCF6",
    bgDark: "#323232",
    bgPrimary: "#FFF7F3",
    bgSecondary: "#F6F8FD",
    bgSecondary2: "#DFDFDF",
    bgPage: "#F8F8F8",
    borderSecondary: "#DFE1EB",
    textGray: "#6F7F9E",
    softGray: "#CCCCCC",
    hardGray: "#333333",
    bgSoftGray: "#F2F2F2",
    chatTextColor: "#464646",
    grey: "#5E6E88",
    midGrey: "#767676",
    blogTags: "F0F0F0",
    white: "#FFFFFF",
    black: "#000000",
    error: "#F23D35",
    bgError: "#FFE9E8",
    light: "#FFFBF1",
    golden: "#E5B80B"
  },
  // A new font theming format test.
  text: {
    family: "Inter, sans-serif",
    defaultLineHeight: "130%",
    color: {
      blue: "#032565",
      blueDimmed: "#6F7F9E",
      orange: "#EA6B26",
      orangeDimmed: "#FFAB7D"
    },
    default: {
      size: "20px",
      weight: "400",
      mobileSize: "18px",
      mobileWeight: "400"
    },
    header: {
      size: "32px",
      weight: "600",
      mobileSize: "24px",
      mobileWeight: "600"
    },
    banner: {
      size: "40px",
      weight: "600",
      mobileSize: "28px",
      mobileWeight: "600"
    }
  },
  font: {
    sizeMenu: "14px",
    size: "16px",
    sizeMedium: "18px",
    sizeSubTitle: "20px",
    sizeTitle: "24px",
    sizeBanner: "32px",
    weightBolder: "800",
    weightBold: "700",
    weightMedium: "600",
    weightSemiMedium: "500",
    weightNormal: "400",
    family: "Inter, sans-serif"
  },
  sizes: {
    borderRadius: "4px",
    sliderRunnableTrack: "0.5em",
    heightHeaderDesktop: "64px",
    maxWidthMainWindow: "1010px",
    windowMainMaxWidth: "1180px",
    maxWidthContainer: "688px",
    headerHeight: "96px",
    mobileHeaderHeight: "66px",
    stickyFooterHeight: "80px",
    input: {
      padding: "0.5em"
    },
    block: {
      padding: "0.5em 1em"
    },
    lineHeight: "130%"
  },
  media: {
    phone: "(max-width: 570px)",
    tablet: "(min-width: 570px) and (max-width: 768px)",
    leftmenu: "(max-width: 845px)"
  }
};

export { theme };
