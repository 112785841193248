import {
  HeaderWrapper,
  ReducedMenu,
  MenuWrapper,
  ListWrapper,
  MenuItem,
  MobileLogoLink
} from "./styles";
import {
  HOME_ROUTE,
  HOST_HOUSE_WIZARD_ROUTE,
  HOST_WIZARD_ROUTE,
  STUDENT_WIZARD_ROUTE
} from "utils/constants";
import { Logo } from "../Icons/Logo";
import {
  NavLink,
  useNavigate,
  useLocation,
  generatePath,
  matchPath
} from "react-router-dom";
import { useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyledButton } from "components/generic";
import { publicRoutes, hostRoutes, studentRoutes } from "../../routes";
import { ConteinerPopup, Item, ItemUser } from "../Menu/styles";
import { PopupMenu } from "../PopupMenu";
import { useContextHospi } from "../../context/ContextHospi";
import useDetectDevice from "../../utils/hooks/useWindowSize";
import { ProfileBurger } from "../MobileMenu/ProfileMenu/styles";
import { PersonIcon } from "./styles";
import TabletMenu from "../MobileMenu/TabletMenu";
import ProfileMenu from "../MobileMenu/ProfileMenu";
import LangBubble from "../LangBubble";
import { isChatPath, useGetProfileMenuRoutes } from "../Menu/utils";
import { useChat } from "../../context/ContextChat/ContextChat";
import { useIsHost } from "../../utils/helpers";
import { Skeleton } from "antd";

export const Header = () => {
  const navigate = useNavigate();
  const {
    cognitoUser,
    currentUserName,
    userProfile,
    isLoadingCognitoUser,
    isLoadingCurrentUser,
    logOut,
    notFinishedRequiredHouseWizard
  } = useContextHospi();

  const { t, i18n } = useTranslation("header");
  const device = useDetectDevice();
  const [leftMenuOpen, setLeftMenuOpen] = useState<boolean>(false);
  const [rightMenuOpen, setRightMenuOpen] = useState<boolean>(false);
  const [registerQueryKey, setRegisterQueryKey] = useState<"h" | "s" | "">("");
  const isHost = useIsHost();

  const location = useLocation();
  const isStudentOrHostRoute =
    studentRoutes.some((route) => matchPath(route.path, location.pathname)) ||
    hostRoutes.some((route) => matchPath(route.path, location.pathname));

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
    setRegisterQueryKey(
      location.pathname.includes("/hospita")
        ? "h"
        : location.pathname.includes("/students")
        ? "s"
        : ""
    );
  }, [location.pathname]);

  useEffect(() => {
    if (!["tablet", "mobile"].includes(device)) {
      setLeftMenuOpen(false);
      setRightMenuOpen(false);
    }
  }, [device, leftMenuOpen]);

  let menuRoutes = useGetProfileMenuRoutes();

  const {
    state: { chatCounters }
  } = useChat();

  const unreadCount = isHost
    ? (chatCounters?.requested ?? 0) + (chatCounters?.unread_accepted ?? 0)
    : chatCounters?.unread_accepted ?? 0;

  return (
    <HeaderWrapper shadow={true} id="hospi-header">
      {/* <Banner
                button={`${t("banner.button")} `}
                text={`${t("banner.text")} `}
                href="https://www.eventbrite.nl/e/tickets-hospi-housing-webinar-verhuur-tijdelijk-een-kamer-in-huis-405115741087?utm_campaign=post_publish&utm_medium=email&utm_source=eventbrite&utm_content=shortLinkNewEmail"
            /> */}
      <div>
        <MenuWrapper>
          <ProfileMenu open={rightMenuOpen} setOpen={setRightMenuOpen} />
        </MenuWrapper>
        <TabletMenu open={leftMenuOpen} setOpen={setLeftMenuOpen} />
        {!["tablet", "mobile"].includes(device) ? (
          <nav>
            <NavLink to={`/${i18n.language}/`} key={HOME_ROUTE}>
              <Logo />
            </NavLink>

            <ListWrapper>
              {!isStudentOrHostRoute &&
                publicRoutes
                  .filter(({ keyLocalize }) => keyLocalize !== "contact")
                  .map((item) => (
                    <MenuItem key={item.path}>
                      <NavLink
                        to={generatePath(item.path, { lng: i18n.language })}
                      >
                        {t(`menu.${item.keyLocalize}`)}
                      </NavLink>
                    </MenuItem>
                  ))}
            </ListWrapper>
            <div>
              <LangBubble />
              {isLoadingCognitoUser || isLoadingCurrentUser ? (
                <Skeleton.Button size="large" active />
              ) : (
                <>
                  {cognitoUser ? (
                    <Item>
                      <PopupMenu title={`${t("menu.login")} `} top="28px">
                        <ConteinerPopup>
                          <ItemUser>
                            <p>{t(`subMenu.signedIn`)}</p>
                            <p>{currentUserName}</p>
                          </ItemUser>
                          <hr />
                          {userProfile ? (
                            <>
                              {menuRoutes.map((item) => (
                                <Item key={item.path} popup>
                                  <NavLink
                                    to={generatePath(item.path, {
                                      lng: i18n.language,
                                      id: isChatPath(item.path) ? "all" : ""
                                    })}
                                  >
                                    <span style={{ position: "relative" }}>
                                      {t(`subMenu.${item.keyLocalize}`)}{" "}
                                      {isChatPath(item.path) && unreadCount > 0
                                        ? ` (${unreadCount})`
                                        : ""}
                                    </span>
                                  </NavLink>
                                </Item>
                              ))}
                              {notFinishedRequiredHouseWizard ? (
                                <Item popup>
                                  <NavLink
                                    to={generatePath(HOST_HOUSE_WIZARD_ROUTE, {
                                      lng: i18n.language,
                                      houseId:
                                        notFinishedRequiredHouseWizard.house_id,
                                      wizardType:
                                        notFinishedRequiredHouseWizard.wizard_type
                                    })}
                                  >
                                    <span style={{ position: "relative" }}>
                                      {t(`subMenu.finishHouseWizard`)}
                                    </span>
                                  </NavLink>
                                </Item>
                              ) : null}
                            </>
                          ) : (
                            <Item popup>
                              <NavLink
                                to={generatePath(
                                  cognitoUser.role === "student"
                                    ? STUDENT_WIZARD_ROUTE
                                    : HOST_WIZARD_ROUTE,
                                  {
                                    lng: i18n.language
                                  }
                                )}
                              >
                                {t(`subMenu.finishUserWizard`)}
                              </NavLink>
                            </Item>
                          )}
                          <hr />
                          <Item
                            className="logout"
                            popup
                            onClick={() => {
                              logOut();
                            }}
                          >
                            {t("subMenu.signOut")}
                          </Item>
                        </ConteinerPopup>
                      </PopupMenu>
                    </Item>
                  ) : (
                    <div>
                      <StyledButton
                        p="9px 17px"
                        outline
                        onClick={() =>
                          navigate(
                            `/${i18n.language}/login${
                              registerQueryKey ? `?m=${registerQueryKey}` : ""
                            }`
                          )
                        }
                      >
                        {t("menu.signIn")}
                      </StyledButton>
                      <StyledButton
                        p="9px 17px"
                        onClick={() =>
                          navigate(
                            `/${i18n.language}/register${
                              registerQueryKey ? `?m=${registerQueryKey}` : ""
                            }`
                          )
                        }
                      >
                        {t("menu.signUp")}
                      </StyledButton>
                    </div>
                  )}
                </>
              )}
            </div>
          </nav>
        ) : (
          <ReducedMenu>
            {isStudentOrHostRoute ? (
              <LangBubble isLeftSide={true} />
            ) : (
              <ProfileBurger
                rightMenuOpen={rightMenuOpen}
                open={leftMenuOpen}
                setOpen={() => {
                  setLeftMenuOpen(!leftMenuOpen);
                  setRightMenuOpen(false);
                }}
              />
            )}
            <MobileLogoLink to={`/${i18n.language}/`} key={HOME_ROUTE}>
              <Logo />
            </MobileLogoLink>
            <PersonIcon
              style={{ zIndex: leftMenuOpen ? 10 : 30 }}
              size="2.2em"
              onClick={() => {
                setLeftMenuOpen(false);
                setRightMenuOpen(!rightMenuOpen);
              }}
            />
          </ReducedMenu>
        )}
      </div>
    </HeaderWrapper>
  );
};
