import {
  Column,
  CopyRightSection,
  Input,
  LanguageSection,
  LanguageWide,
  MailSection,
  Title,
  Wrapper,
  CustomLink,
  FooterRow,
  CustomRow
} from "./styles";
import { Select } from "antd";
import {
  ABOUT_US,
  COLLABORATIONS,
  FOR_HOSTS,
  FOR_STUDENTS,
  LEGAL,
  SUPPORT
} from "./constants";
import { Button, StyledButton, TextPlain, TitleH2 } from "../generic";
import { useSizeWindow } from "../../utils/hooks/useWindowSize";
import { lazy, useEffect, useState } from "react";
import {
  Facebook,
  Instagram,
  Tiktok,
  LinkedIn,
  Youtube
} from "../Icons/SocialIcons";
import { useTranslation } from "react-i18next";
import { DialogWrapper } from "../containers";
import { SmallLoader } from "../SmallLoader";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

const ModalLazy = lazy(() => import("../Modal"));

export const Footer = () => {
  const { width } = useSizeWindow();
  const { t, i18n } = useTranslation(["footer", "contact"]);
  const navigate = useNavigate();
  const location = useLocation();
  const changeLanguage = (language: string): void => {
    navigate(
      {
        pathname: `/${language.toLowerCase()}/${location.pathname.slice(4)}${
          location.search
        }`
      },
      { replace: true }
    );
    i18n.changeLanguage(language.toLocaleLowerCase());
  };

  const LANGUAGE_OPTIONS = [
    {
      label: t("footer.gb"),
      value: "gb"
    },
    {
      label: t("footer.nl"),
      value: "nl"
    }
  ];

  const [showModal, setShowModal] = useState<number>(0);
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  function isValidEmail(email: string) {
    return /\S+@\S+\.\S+/.test(email);
  }

  useEffect(() => {
    setEmailError(
      !isValidEmail(email) && email ? "formSection.errors.invalidEmail" : ""
    );
  }, [email]);

  const sendData = () => {
    setLoading(true);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        type: "newsletter",
        browser_language: i18n.language
      })
    };

    console.log(
      process.env.REACT_APP_AWS_ENDPOINT! +
        `contact-us/${email.trim() as string}`
    );
    fetch(
      process.env.REACT_APP_AWS_ENDPOINT! +
        `contact-us/${email.trim() as string}`,
      requestOptions
    )
      .then((response) => response.json())
      .then(() => {
        setShowModal(1);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
    setEmail("");
  };

  const renderList = (list: { title: string; link: string }[]) => {
    return (
      <ul>
        {list.map(({ link, title }) => (
          <li key={title}>
            <NavLink to={`/${i18n.language}${link}?f=f`}>
              {t(`footer.${title}`)}
            </NavLink>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <>
      <Wrapper>
        <MailSection>
          <div>
            <Title>{t("footer.signUpEmail")}</Title>
            <p>{t("footer.latestNews")}</p>
          </div>
          <div style={{ display: "flex" }}>
            <Input
              name="email"
              error={!!emailError}
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              type="email"
              autoComplete="email"
              placeholder={t("contact.form.email", { ns: "contact" }).slice(
                0,
                t("contact.form.email", { ns: "contact" }).length - 1
              )}
            />
            <StyledButton p="9px 17px" disabled={loading} onClick={sendData}>
              {loading ? (
                <SmallLoader
                  minWidth={70}
                  height={18}
                  borderWidth={2}
                  color="white"
                />
              ) : (
                t("footer.signUp")
              )}
            </StyledButton>
          </div>
        </MailSection>
        <FooterRow>
          <Column>
            <div>
              <Title>{t("footer.aboutUs")}</Title>
              {renderList(ABOUT_US)}
            </div>
            <div>
              <Title>{t("footer.forHosts")}</Title>
              {renderList(FOR_HOSTS)}
            </div>
          </Column>
          <Column>
            <div>
              <Title>{t("footer.support")}</Title>
              {renderList(SUPPORT)}
            </div>
            <div>
              <Title>{t("footer.legal")}</Title>
              {renderList(LEGAL)}
              <CustomLink
                href="https://hospihousing-documents.s3.eu-west-1.amazonaws.com/Hospi+Housing+general+terms+and+conditions.pdf"
                target="_blank"
                rel="noreferrer"
              >
                {t("footer.teamConditions")}
              </CustomLink>
            </div>
          </Column>
        </FooterRow>
        <FooterRow style={{ borderBottom: "1px solid #6f7f9e" }}>
          <div className="oneSubMenuColumn">
            <Title>{t("footer.collaborations")}</Title>
            {renderList(COLLABORATIONS)}
          </div>
          <CustomRow>
            <div className="wideSubMenu">
              <Title>{t("footer.forStudents")}</Title>
              {renderList(FOR_STUDENTS)}
            </div>
            {width > 425 && (
              <LanguageSection>
                <Title style={{ marginBottom: 4 }}>
                  {t("footer.language")}
                </Title>
                <Select
                  style={{ width: "100%" }}
                  value={i18n.language}
                  onChange={changeLanguage}
                  options={LANGUAGE_OPTIONS}
                  id="language"
                />
              </LanguageSection>
            )}
          </CustomRow>
        </FooterRow>
        {width <= 425 && (
          <LanguageWide>
            <Title>{t("footer.language")}</Title>
            <Select
              style={{
                width: "100%"
              }}
              onChange={changeLanguage}
              options={LANGUAGE_OPTIONS}
              id="language"
            />
          </LanguageWide>
        )}

        <CopyRightSection>
          <span>{t("footer.copyRight")}</span>
          <span id="kvk">KvK 74539787</span>
          <div>
            <a
              href="https://www.facebook.com/Hospihousing"
              target="_blank"
              rel="noreferrer"
            >
              {Facebook}
            </a>
            <a
              href="https://www.instagram.com/hospihousing/"
              target="_blank"
              rel="noreferrer"
            >
              {Instagram}
            </a>
            <a
              href="https://www.tiktok.com/@hospihousing"
              target="_blank"
              rel="noreferrer"
            >
              {Tiktok}
            </a>
            <a
              href="https://www.linkedin.com/company/hospi-housing"
              target="_blank"
              rel="noreferrer"
            >
              {LinkedIn}
            </a>
            <a
              href="https://youtube.com/@hospihousing3621"
              target="_blank"
              rel="noreferrer"
            >
              {Youtube}
            </a>
          </div>
        </CopyRightSection>
      </Wrapper>
      {
        <ModalLazy isShow={!!showModal} handleClose={() => setShowModal(0)}>
          <DialogWrapper>
            <TitleH2
              style={{
                textAlign: "left"
              }}
            >
              {t(`contact.email.title`, { ns: "contact" })}
            </TitleH2>
            <TextPlain
              style={{
                whiteSpace: "pre-line",
                wordBreak: "break-word",
                textAlign: "left"
              }}
            >
              {t(`contact.email.textSubscribe`, { ns: "contact" })}
            </TextPlain>
            <div className="buttons">
              <span />
              <Button main onClick={() => setShowModal(0)}>
                {t(`contact.closeBox`, { ns: "contact" })}
              </Button>
            </div>
          </DialogWrapper>
        </ModalLazy>
      }
    </>
  );
};
