import styled from "styled-components/macro";
import { MixinFont } from "../mixins";

export const BubbleCont = styled.div`
  margin-right: 2em;
  transform: translate(0px, -1px);
  align-self: center;
  ${MixinFont};
  color: ${(props) => props.theme.colors.textDimmed};
  height: 100%;
  display: flex;
  align-items: center;
`;

export const MainCont = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  cursor: pointer;
  min-height: 60px;

  :hover {
    color: ${(props) => props.theme.colors.text};
  }

  & > p {
    margin-bottom: 0;
    margin-left: 4px;
    transform: translate(0px, 1px);
  }

  & > svg {
  }

  :hover > .arrow > path {
    stroke: ${(props) => props.theme.colors.text};
  }

  .arrow {
    padding-left: 0;
    path {
      stroke: ${(props) => props.theme.colors.textDimmed};
    }
  }
`;
export const SelectCont = styled.div<{ $isLeftSide?: boolean }>`
  position: absolute;
  top: 76px;
  left: ${({ $isLeftSide }) => (!$isLeftSide ? "-72px" : "-4px")};
  border: 1px solid ${({ theme }) => theme.colors.borderSecondary};
  border-radius: 4px;
  cursor: pointer;
  z-index: 9999999;
  background-color: #ffffff;

  li {
    list-style: none;
    color: ${({ theme }) => theme.colors.textGray};
    min-width: 160px;
    padding: 12px 12px 12px 16px;
    font-weight: ${({ theme }) => theme.font.weightMedium};

    :hover {
      color: ${({ theme }) => theme.colors.text};
      background-color: ${({ theme }) => theme.colors.place};
    }
  }
`;

export const SvgBubble = styled.div`
  & > svg {
    transform: translate(0px, 2px);
    border: 1px solid #dfe1eb;
    border-radius: 99px;
  }
`;
