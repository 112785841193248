import { matchPath } from "react-router-dom";

import { hostRoutes, studentRoutes, TMenuRoute } from "routes";
import { hostMenuRoutes, studentMenuRoutes } from "./index";
import { useIsHost } from "utils/helpers";
import {
  HOST_CHATS_ROUTE_ID,
  HOST_TIPS_ROUTE,
  STUDENT_CHATS_ROUTE_ID,
  STUDENT_TIPS_ROUTE
} from "utils/constants";
import { useContextHospi } from "../../context/ContextHospi";
import { ThumbUpIcon } from "../Icons/ThumbUp";

export const useGetProfileMenuRoutes = (): TMenuRoute[] => {
  const isHost = useIsHost();
  const { currentUserRole } = useContextHospi();

  if (!currentUserRole) {
    return [];
  }

  return (
    (isHost ? hostRoutes : studentRoutes).filter((item) => {
      return (isHost ? hostMenuRoutes : studentMenuRoutes).some((menuPath) => {
        return Boolean(matchPath(item.path, menuPath));
      });
    }) as TMenuRoute[]
  ).concat({
    keyLocalize: "tips",
    icon: <ThumbUpIcon />,
    path: isHost ? HOST_TIPS_ROUTE : STUDENT_TIPS_ROUTE
  } as TMenuRoute);
};

export const isChatPath = (path: string): boolean => {
  return (
    !!matchPath(path, STUDENT_CHATS_ROUTE_ID) ||
    !!matchPath(path, HOST_CHATS_ROUTE_ID)
  );
};
