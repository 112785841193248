import {
  IHouse,
  IHouseRepublishPayload,
  IHouseWithRentPeriod,
  IResponseShort,
  THouseTogglePublishPayload
} from "interfaces/interfaces";
import { ServiceApi } from "../utils/utilAmplify";
import query from "../utils/query";
import { ServiceName } from "../config/amplify";
import {
  IHouseStepAboutHousehold,
  IHouseStepAboutSpace,
  IHouseStepActivityExpectations,
  IHouseStepAvailability,
  IHouseStepFacilities,
  IHouseStepHouseLocation,
  IHouseStepIdealTenant,
  IHouseStepPhotos,
  IHouseStepPrices,
  IHouseStepFinishConfig,
  IHouseStepSpaceType,
  IHouseStepUniqueness,
  IWizardHouseState,
  IWizardHouseStateShort,
  THousePhoto,
  TStepPayload,
  TWizardHouseType
} from "../components/Wizard/interfaces";

class HouseAPIClass {
  private readonly api: ServiceApi;

  constructor() {
    this.api = new ServiceApi(ServiceName.HostHouse);
  }

  updateHouse(
    id: string,
    body: Partial<IHouse>
  ): Promise<IHouseWithRentPeriod> {
    return this.api.patch(id, body, {
      showNotificationOnError: false
    });
  }

  createHouse(body: Partial<IHouse>): Promise<IHouseWithRentPeriod> {
    return this.api.post("", body);
  }

  getHouse(id: string): Promise<IHouseWithRentPeriod> {
    return this.api.get(id);
  }

  deleteHouse(id: string) {
    return this.api.del(id);
  }

  getHouses(userId: string): Promise<IHouseWithRentPeriod[]> {
    return this.api.get("?" + query.stringify({ user_id: userId }));
  }

  changeLiked(
    isLiked: boolean,
    studentId: string,
    hostId: string,
    houseId: string
  ): Promise<IResponseShort> {
    return this.api.post((isLiked ? `un-like` : `like`) + "/" + houseId, {
      student_id: studentId,
      host_id: hostId,
      house_id: houseId
    });
  }

  publishHouse(id: string): Promise<IHouseWithRentPeriod> {
    return this.api.post(`${id}/toggle-publishing`);
  }

  depublishHouse(
    id: string,
    data: THouseTogglePublishPayload
  ): Promise<IHouseWithRentPeriod> {
    return this.api.post(`${id}/toggle-publishing`, data);
  }

  republishHouse(
    id: string,
    data: IHouseRepublishPayload
  ): Promise<IHouseWithRentPeriod> {
    return this.api.post(`${id}/re-publish`, data);
  }

  availableByRadius(params: {
    userId: string;
    lat: number;
    lon: number;
    radiusInMeters: number;
    languages: string[];
    gender: string;
    age: number;
    price?: number;
    area?: number;
    dateFrom?: string;
    dateTo?: string;
    isLiked?: boolean;
  }): Promise<{ items: IHouse[]; next_batch_token: string | null }> {
    return this.api
      .get(
        "available-by-radius?" +
          query.stringify(
            {
              latitude: params.lat,
              longitude: params.lon,
              radius_in_meters: params.radiusInMeters,
              user_id: params.userId,
              languages: params.languages,
              gender: params.gender,
              age: params.age,
              price: params.price,
              area: params.area,
              date_from: params.dateFrom,
              date_to: params.dateTo,
              is_liked: params.isLiked
            },
            {
              arrayFormat: "repeat"
            }
          )
      )
      .then((result: { items: IHouse[]; next_batch_token: string | null }) => {
        return {
          items: result.items.map((item) => ({
            ...item,
            latitude: parseFloat(item.latitude as unknown as string),
            longitude: parseFloat(item.longitude as unknown as string)
          })),
          next_batch_token: result.next_batch_token
        };
      });
  }

  uploadPhoto(
    houseID: string,
    body: File | string,
    timestamp: number,
    order: number
  ): Promise<THousePhoto> {
    const formData = new FormData();
    formData.append("image", body);

    return this.api.post(
      `${houseID}/photos?` + query.stringify({ timestamp, order }),
      formData,
      {
        headers: {
          Accept: "image/*"
        }
      }
    );
  }

  deletePhotos(houseID: string, fileNames: string[]) {
    return this.api.del(`${houseID}/photos`, {
      file_names: fileNames
    });
  }

  reorderPhotos(
    houseID: string,
    order: { file_name: string; order: number }[]
  ) {
    return this.api.post(
      `${houseID}/set-photos-order`,
      { order_data: order },
      {
        headers: {
          Accept: "image/*"
        }
      }
    );
  }

  getWizardsStateShort(): Promise<IWizardHouseStateShort[]> {
    return this.api.get("wizard/state");
  }

  getWizardState(
    houseId: string,
    wizardType: TWizardHouseType
  ): Promise<IWizardHouseState> {
    return this.api.get(`wizard/state/${houseId}/${wizardType}`);
  }

  createWizard(type: TWizardHouseType): Promise<IWizardHouseStateShort> {
    return this.api.post(`wizard/create/${type}`);
  }

  finishWizard(houseId: string, wizardType: string): Promise<IResponseShort> {
    return this.api.post(`wizard/finish/${houseId}/${wizardType}`);
  }

  deleteWizard(houseId: string, wizardType: string): Promise<IResponseShort> {
    return this.api.del(`wizard/delete/${houseId}/${wizardType}`);
  }

  wizardSaveSpaceType(
    houseId: string,
    wizardType: string,
    value: TStepPayload<IHouseStepSpaceType>
  ): Promise<IHouseStepSpaceType> {
    return this.api.post(
      `wizard/step/space_type/${houseId}/${wizardType}`,
      value,
      {
        showNotificationOnError: false
      }
    );
  }

  wizardSaveHouseLocation(
    houseId: string,
    wizardType: string,
    value: TStepPayload<IHouseStepHouseLocation>
  ): Promise<IHouseStepHouseLocation> {
    return this.api.post(
      `wizard/step/house_location/${houseId}/${wizardType}`,
      value,
      {
        showNotificationOnError: false
      }
    );
  }

  wizardSaveHouseLocationPartially(
    houseId: string,
    wizardType: string,
    value: TStepPayload<IHouseStepHouseLocation>
  ): Promise<IHouseStepHouseLocation> {
    return this.api.patch(
      `wizard/step/house_location/${houseId}/${wizardType}`,
      value,
      {
        showNotificationOnError: false
      }
    );
  }

  wizardSaveAboutHousehold(
    houseId: string,
    wizardType: string,
    value: TStepPayload<IHouseStepAboutHousehold>
  ): Promise<IHouseStepAboutHousehold> {
    return this.api.post(
      `wizard/step/about_household/${houseId}/${wizardType}`,
      value,
      {
        showNotificationOnError: false
      }
    );
  }

  wizardSaveAboutHouseholdPartially(
    houseId: string,
    wizardType: string,
    value: TStepPayload<IHouseStepAboutHousehold>
  ): Promise<IHouseStepAboutHousehold> {
    return this.api.patch(
      `wizard/step/about_household/${houseId}/${wizardType}`,
      value,
      {
        showNotificationOnError: false
      }
    );
  }

  wizardSaveAboutSpace(
    houseId: string,
    wizardType: string,
    value: TStepPayload<IHouseStepAboutSpace>
  ): Promise<IHouseStepAboutSpace> {
    return this.api.post(
      `wizard/step/about_space/${houseId}/${wizardType}`,
      value,
      {
        showNotificationOnError: false
      }
    );
  }

  wizardSaveAboutSpacePartially(
    houseId: string,
    wizardType: string,
    value: TStepPayload<IHouseStepAboutSpace>
  ): Promise<IHouseStepAboutSpace> {
    return this.api.patch(
      `wizard/step/about_space/${houseId}/${wizardType}`,
      value,
      {
        showNotificationOnError: false
      }
    );
  }

  wizardSaveFacilities(
    houseId: string,
    wizardType: string,
    value: TStepPayload<IHouseStepFacilities>
  ): Promise<IHouseStepFacilities> {
    return this.api.post(
      `wizard/step/facilities/${houseId}/${wizardType}`,
      value,
      {
        showNotificationOnError: false
      }
    );
  }

  wizardSaveFacilitiesPartially(
    houseId: string,
    wizardType: string,
    value: TStepPayload<IHouseStepFacilities>
  ): Promise<IHouseStepFacilities> {
    return this.api.patch(
      `wizard/step/facilities/${houseId}/${wizardType}`,
      value,
      {
        showNotificationOnError: false
      }
    );
  }

  wizardSaveUniqueness(
    houseId: string,
    wizardType: string,
    value: TStepPayload<IHouseStepUniqueness>
  ): Promise<IHouseStepUniqueness> {
    return this.api.post(
      `wizard/step/uniqueness/${houseId}/${wizardType}`,
      value,
      {
        showNotificationOnError: false
      }
    );
  }

  wizardSaveUniquenessPartially(
    houseId: string,
    wizardType: string,
    value: TStepPayload<IHouseStepUniqueness>
  ): Promise<IHouseStepUniqueness> {
    return this.api.patch(
      `wizard/step/uniqueness/${houseId}/${wizardType}`,
      value,
      {
        showNotificationOnError: false
      }
    );
  }

  wizardPhotosUpload(
    houseId: string,
    wizardType: string,
    timestamp: number,
    order: number,
    image: File,
    { showNotificationOnError = true }
  ): Promise<THousePhoto> {
    const formData = new FormData();
    formData.append("image", image);
    return this.api
      .post(
        `wizard/step/photos/upload/${houseId}/${wizardType}?timestamp=${timestamp}&order=${order}`,
        formData,
        {
          Accept: "image/jpeg",
          showNotificationOnError
        }
      )
      .then((result: IHouseStepPhotos) => {
        return result.photos?.find(
          (p) => parseInt(p.timestamp!) === timestamp
        )!;
      });
  }

  wizardPhotosDelete(
    houseId: string,
    wizardType: string,
    fileName: string
  ): Promise<IHouseStepPhotos> {
    return this.api.del(
      `wizard/step/photos/remove/${fileName.replace(
        "downloads/",
        ""
      )}/${houseId}/${wizardType}`
    );
  }

  wizardUpdatePhotosOrder(
    houseId: string,
    wizardType: string,
    order: { file_name: string; order: number }[]
  ): Promise<IHouseStepPhotos> {
    return this.api.post(
      `wizard/step/photos/set-photos-order/${houseId}/${wizardType}`,
      { order_data: order }
    );
  }

  wizardPhotosUploadFinish(
    houseId: string,
    wizardType: string
  ): Promise<IHouseStepPhotos> {
    return this.api.post(
      `wizard/step/photos/finish/${houseId}/${wizardType}`,
      {},
      {
        showNotificationOnError: false
      }
    );
  }

  wizardSaveAvailability(
    houseId: string,
    wizardType: string,
    value: TStepPayload<IHouseStepAvailability>
  ): Promise<IHouseStepAvailability> {
    return this.api.post(
      `wizard/step/availability/${houseId}/${wizardType}`,
      value,
      {
        showNotificationOnError: false
      }
    );
  }

  wizardSaveAvailabilityPartially(
    houseId: string,
    wizardType: string,
    value: TStepPayload<IHouseStepAvailability>
  ): Promise<IHouseStepAvailability> {
    return this.api.patch(
      `wizard/step/availability/${houseId}/${wizardType}`,
      value,
      {
        showNotificationOnError: false
      }
    );
  }

  wizardSavePrices(
    houseId: string,
    wizardType: string,
    value: TStepPayload<IHouseStepPrices>
  ): Promise<IHouseStepPrices> {
    return this.api.post(`wizard/step/prices/${houseId}/${wizardType}`, value, {
      showNotificationOnError: false
    });
  }

  wizardSavePricesPartially(
    houseId: string,
    wizardType: string,
    value: TStepPayload<IHouseStepPrices>
  ): Promise<IHouseStepPrices> {
    return this.api.patch(
      `wizard/step/prices/${houseId}/${wizardType}`,
      value,
      {
        showNotificationOnError: false
      }
    );
  }

  wizardSaveIdealTenant(
    houseId: string,
    wizardType: string,
    value: TStepPayload<IHouseStepIdealTenant>
  ): Promise<IHouseStepIdealTenant> {
    return this.api.post(
      `wizard/step/ideal_tenant/${houseId}/${wizardType}`,
      value,
      {
        showNotificationOnError: false
      }
    );
  }

  wizardSaveIdealTenantPartially(
    houseId: string,
    wizardType: string,
    value: TStepPayload<IHouseStepIdealTenant>
  ): Promise<IHouseStepIdealTenant> {
    return this.api.patch(
      `wizard/step/ideal_tenant/${houseId}/${wizardType}`,
      value,
      {
        showNotificationOnError: false
      }
    );
  }

  wizardSaveActivityExpectations(
    houseId: string,
    wizardType: string,
    value: TStepPayload<IHouseStepActivityExpectations>
  ): Promise<IHouseStepActivityExpectations> {
    return this.api.post(
      `wizard/step/activity_expectations/${houseId}/${wizardType}`,
      value,
      {
        showNotificationOnError: false
      }
    );
  }

  wizardSaveActivityExpectationsPartially(
    houseId: string,
    wizardType: string,
    value: TStepPayload<IHouseStepActivityExpectations>
  ): Promise<IHouseStepActivityExpectations> {
    return this.api.patch(
      `wizard/step/activity_expectations/${houseId}/${wizardType}`,
      value,
      {
        showNotificationOnError: false
      }
    );
  }

  wizardSaveFinishConfig(
    houseId: string,
    wizardType: string,
    value: TStepPayload<IHouseStepFinishConfig>
  ): Promise<IHouseStepFinishConfig> {
    return this.api.post(
      `wizard/step/finish_config/${houseId}/${wizardType}`,
      value,
      {
        showNotificationOnError: false
      }
    );
  }

  wizardCreateFromHouse(houseId: string): Promise<IWizardHouseState> {
    return this.api.post(`wizard/import/${houseId}`);
  }
}

export const HouseAPI = new HouseAPIClass();
