import {
  IFulfilledChat,
  MessageTypeNames,
  TMessage
} from "../../interfaces/interfacesChat";
import { parseServerDateTime } from "../../utils/time";
import { sortMessagesFunction } from "../../utils/chat";

export const sortChats = (chats: IFulfilledChat[]) => {
  return chats.sort((a, b) => {
    const aValue = parseServerDateTime(a.updated_at).getTime();
    const bValue = parseServerDateTime(b.updated_at).getTime();
    return aValue < bValue ? 1 : -1;
  });
};

export const prepareChatMessages = (messages: TMessage[]) => {
  return messages
    .filter((m) => {
      return (
        m.msg_type !== MessageTypeNames.REMOVE_CHAT &&
        m.msg_type !== MessageTypeNames.UNREMOVE_CHAT
      );
    })
    .sort(sortMessagesFunction);
};
