import {
  IMatch,
  IBaseMatchData,
  TMatchData,
  ICancelMatchData,
  IUpdateCheckoutDatePayload,
  IResponseShort
} from "interfaces/interfaces";
import { ServiceApi } from "../utils/utilAmplify";
import { ServiceName } from "../config/amplify";
import query from "../utils/query";

class HostAPIClass {
  private readonly api: ServiceApi;
  constructor() {
    this.api = new ServiceApi(ServiceName.HostMatches);
  }
  getHostMatches(hostId: string): Promise<TMatchData[]> {
    return this.api.get(`host-matches/${hostId}`);
  }
  getMatch(
    studentId: string,
    hostId: string,
    houseId: string,
    options?: { showNotificationOnError?: boolean }
  ): Promise<IMatch> {
    return this.api
      .get(
        "details?" +
          query.stringify({
            student_id: studentId,
            host_id: hostId,
            house_id: houseId
          }),
        options
      )
      .then((res) => res.data);
  }
  invite(
    payload: Required<
      Pick<
        IMatch,
        | "host_id"
        | "house_id"
        | "student_id"
        | "student_check_in_date"
        | "student_check_out_date"
      >
    >
  ) {
    return this.api.post("invite", payload);
  }
  cancelInvite(payload: IBaseMatchData) {
    return this.api.post("cancel-invite", payload);
  }
  cancelMatch(payload: ICancelMatchData) {
    return this.api.post("remove-tenant", payload);
  }
  updateOfferedDates(
    payload: Required<
      Pick<
        IMatch,
        | "host_id"
        | "house_id"
        | "student_id"
        | "student_check_in_date"
        | "student_check_out_date"
      >
    >
  ) {
    return this.api.post("update-offered-dates", payload);
  }
  updateCheckoutDate(
    payload: IUpdateCheckoutDatePayload
  ): Promise<IResponseShort> {
    return this.api.post("change-checkout-date", payload);
  }
}

export const HostAPI = new HostAPIClass();
