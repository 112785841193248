import { ReactNode, FC, useState, useRef } from "react";
import useOnClickOutside from "utils/hooks/useOnClickOutside";
import useHover from "utils/hooks/useHover";
import { ContainerPopupMenu } from "../containers";
import { ArrowDown, ContainerPopup } from "./styles";

interface IPopupMenu {
  title: ReactNode;
  top?: string;
  right?: string;
  children?: ReactNode;
}

export const PopupMenu: FC<IPopupMenu> = ({ title, top, right, children }) => {
  const [show, setShow] = useState(false);
  const ref = useRef(null);
  const [hoverRef, isHovered] = useHover<HTMLDivElement>();
  useOnClickOutside(ref, () => !isHovered && setShow(false));

  return (
    <ContainerPopup onClick={() => setShow(!show)} ref={hoverRef}>
      <section>
        {title}
        <ArrowDown size="2em" up={show} />
      </section>
      {show && (
        <ContainerPopupMenu
          top={top}
          right={right}
          className={show ? "panel active" : "panel"}
          ref={ref}
        >
          {children}
        </ContainerPopupMenu>
      )}
    </ContainerPopup>
  );
};
